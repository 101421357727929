<template>
  <div>
    <v-date-picker
      class="rounded-0"
      v-model="date"
      full-width
      header-color="orange lighten-1"
      color="orange lighten-1"
      no-title
      :first-day-of-week="1"
    ></v-date-picker>
    <v-spacer />
    <v-row>
      <v-col cols="12">
        <p class="pa-3">
          This map shows all the job locations. You can select whether to view all jobs for the
          current selected week, or just the jobs for a single day. You can also select whether
          to view all jobs, converted jobs only or planned jobs only. Please note, only the 30
          most recent converted jobs are shown.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SideNavMap',
  computed: {
    date: {
      get() {
        return this.$store.state.date;
      },
      set(value) {
        this.$store.commit('setDate', value);
      },
    },
  },
};
</script>
