<template>
  <v-text-field
    :value="$store.state.vehicleSearch"
    @input="$store.dispatch('setVehicleSearch', $event)"
    class="mt-1"
    placeholder="Seach Vehicle"
    prepend-inner-icon="mdi-magnify"
    solo-inverted
    dense
    clearable
  ></v-text-field>
</template>

<script>
export default {
  name: 'SearchVehicles',
};
</script>
