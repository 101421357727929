<template>
  <div>
    <h6 class="text-h6 pl-5 pt-4">All Vehicles</h6>
    <div class="pl-2 pr-2">
      <search-vehicles />
    </div>
    <v-list two-line>
      <v-list-item
        v-for="(vehicle, index) in vehicles"
        :key="index"
      >
        <v-list-item-icon
          class="selectable mt-3 mb-0"
          @click="$store.commit('selectVehicle', vehicle.id)"
        >
          <v-icon color="orange lighten-1">
            mdi-van-utility
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pt-0">
          <v-list-item-title>{{ vehicle.reg }}</v-list-item-title>
          <v-list-item-subtitle>Dept: {{ vehicle.department }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="vehicle.chargehandName">
            {{ vehicle.chargehandName }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="vehicle.worker1Name">
            {{ vehicle.worker1Name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="vehicle.worker2Name">
            {{ vehicle.worker2Name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="vehicle.worker3Name">
            {{ vehicle.worker3Name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import SearchVehicles from '../Tools/SearchVehicles.vue';

export default {
  name: 'SideNavVehicles',
  components: {
    SearchVehicles,
  },
  computed: {
    vehicles() {
      return this.$store.getters.filteredVehicles;
    },
  },
};
</script>
