<template>
  <div>
    <h6 class="text-h6 pl-5 pt-4">Help</h6>
  </div>
</template>

<script>
export default {
  name: 'SideNavHelp',
};
</script>
