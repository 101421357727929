<template>
  <div>
    <h6 class="text-h6 pl-5">Jobs</h6>
    <search-jobs class="ml-2 mr-2" />
    <v-list two-line>
      <v-list-item
        v-for="(job, index) in jobs"
        :key="index"
      >
        <v-list-item-icon
          class="selectable"
          @click="$store.dispatch('selectJob', job.id)"
        >
          <v-icon color="orange lighten-1">
            mdi-hammer-screwdriver
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ job.jobNumber}}</v-list-item-title>
          <v-list-item-subtitle>
            Dept:
            <span v-if="job.department === 1">Paint</span>
            <span v-if="job.department === 2">Thermo</span>
            <span v-if="job.department === 3">Paint & Thermo</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle>{{ job.clientName }}</v-list-item-subtitle>
          <p class="text-body-2">{{ job.jobAddress }}</p>
          <p
            class="text-body-2"
            :class="{ amberAlert: (job.alertLevel === 'amber') }"
          >
            {{ job.created | shortDate }}
          </p>
          <p>{{ job.jobValue | priceInPounds | currency }}</p>
          <p>
            <router-link :to="`/live-jobs/view/${job.id}`">
              <v-btn
                small
                color="orange accent-2"
                >
                <v-icon left>
                  mdi-card-bulleted-outline
                </v-icon>
                Job Card
              </v-btn>
            </router-link>
          </p>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import SearchJobs from '@/components/Tools/SearchJobs.vue';
import { isBefore } from 'date-fns';

export default {
  name: 'JobList',
  components: {
    SearchJobs,
  },
  data() {
    return {
      jobs: [],
    };
  },
  computed: {
    listJobs() {
      return this.$store.getters.filteredConvertedJobs;
    },
    foundJobs() {
      return this.$store.state.foundJobs;
    },
    departmentJobs() {
      return this.$store.state.departmentJobs;
    },
  },
  watch: {
    listJobs() {
      if (this.jobs) {
        const checkedJobs = [];
        for (let i = 0; this.jobs.length > i; i += 1) {
          checkedJobs.push(this.jobs[i]);
          if (this.checkDate(this.jobs[i].created) === 'amber') {
            checkedJobs[i].alertLevel = 'amber';
          } else {
            checkedJobs[i].alertLevel = 'green';
          }
        }
        this.jobs = checkedJobs;
      }
    },
    departmentJobs() {
      if (this.departmentJobs.length > 0) {
        this.jobs = this.departmentJobs;
      } else {
        this.jobs = this.listJobs;
      }
    },
    foundJobs() {
      if (this.foundJobs.length > 0) {
        this.jobs = this.foundJobs;
      } else {
        this.jobs = this.listJobs;
      }
    },
  },
  methods: {
    checkDate(date) {
      let alertLevel = 'green';
      const today = new Date();
      const twoWeeksAgo = new Date(today.setDate(today.getDate() - 14));
      const testDate = new Date(date);
      if (isBefore(testDate, twoWeeksAgo)) {
        alertLevel = 'amber';
      }
      return alertLevel;
    },
    getInitialJobs() {
      setTimeout(() => {
        this.jobs = this.$store.getters.filteredConvertedJobs;
      }, 2000);
    },
  },
  mounted() {
    this.jobs = this.getInitialJobs();
  },
};
</script>

<style lang="scss">
  .selectable {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .amberAlert {
    color: #F57C00;
    font-weight: bold!important;
    font-style: italic;
  }
</style>
