<template>
  <v-icon
    v-if="$store.state.drawer"
    @click="$store.commit('toggleDrawer')"
    large
  >
    mdi-chevron-double-left
  </v-icon>
  <v-icon
    v-else
    @click="$store.commit('toggleDrawer')"
    large
  >
    mdi-chevron-double-right
  </v-icon>
</template>

<script>
export default {
  name: 'SideNavToggle',
};
</script>
