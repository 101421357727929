<template>
  <div>
    <h6 class="text-h6 pl-5 pt-4">All Workers</h6>
    <search-workers class="pl-3 pr-3" />
    <v-list two-line>
      <v-list-item
        v-for="(worker, index) in workers"
        :key="index"
      >
        <v-list-item-icon
          class="selectable mt-3 mb-0"
          @click="$store.commit('selectWorker', worker.id)"
        >
          <v-icon color="orange lighten-1">
            mdi-account
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pt-0">
          <v-list-item-title>{{ worker.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import SearchWorkers from '@/components/Tools/SearchWorkers.vue';

export default {
  name: 'SideNavVehicles',
  components: {
    SearchWorkers,
  },
  computed: {
    workers() {
      return this.$store.getters.filteredWorkers;
    },
  },
};
</script>
