<template>
  <v-row>
    <v-col class="col-2">
      <div class="planning-side-nav">
        <SideNav v-if="isPlan" />
        <SideNavMap v-if="isMap" />
        <SideNavHelp v-if="isHelp" />
        <SideNavHolidays v-if="isHolidays" />
        <SideNavVehicles v-if="isVehicles" />
      </div>
    </v-col>
    <v-col class="col-10">
      <div class="planning-navigation-box">
        <Navigation />
      </div>
      <router-view></router-view>
    </v-col>
  </v-row>
</template>

<script>
import Navigation from '../../components/PlanningNavigation/TopNav.vue';
import SideNav from '../../components/PlanningNavigation/SideNav.vue';
import SideNavHelp from '../../components/PlanningNavigation/SideNavHelp.vue';
import SideNavHolidays from "@/components/PlanningNavigation/SideNavHolidays.vue";
import SideNavMap from '../../components/PlanningNavigation/SideNavMap.vue';
import SideNavVehicles from "@/components/PlanningNavigation/SideNavVehicles.vue";

export default {
  name:'PlanningBoard',
  components: {
    Navigation,
    SideNav,
    SideNavHelp,
    SideNavHolidays,
    SideNavMap,
    SideNavVehicles,
  },
  computed: {
    isPlan() {
      return this.$route.name === 'PlanningBoardPlan';
    },
    isVehicles() {
      return this.$route.name === 'PlanningBoardVehicles';
    },
    isHelp() {
      return this.$route.name === 'PlanningBoardHelp';
    },
    isHolidays() {
      return this.$route.name === 'PlanningBoardHolidays';
    },
    isMap() {
      return this.$route.name === 'Map';
    },
  },
};
</script>
