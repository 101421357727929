<template>
  <v-text-field
    :value="$store.state.workerSearch"
    @input="$store.commit('setWorkerSearch', $event)"
    class="mt-1"
    placeholder="Seach Worker"
    prepend-inner-icon="mdi-magnify"
    solo-inverted
    dense
  ></v-text-field>
</template>

<script>
export default {
  name: 'SearchWorkers',
};
</script>
